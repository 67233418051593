@import "../../../../scss/index.scss";

.add-edit-post-form {
  text-align: center;

  .ant-form-item {
    margin-top: 5px;
    margin-bottom: 5px;

    i {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .tox-tinymce {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn-submit {
    width: 100%;
  }
}
