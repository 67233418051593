@import "../../../scss/index.scss";

.menu-top-web {
  position: sticky;
  top: 0;
  background-color: $background-dark-web;
  border: 0;
  display: flex;
  align-items: center;

  li {
    border: 0 !important;

    a {
      color: #fff !important;
      font-weight: bold;

      &:hover {
        color: $primary-color !important;
      }
    }
  }

  &__logo {
    padding: 10px;

    img {
      width: 200px;
    }
  }
}
