.menu-web-list {
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__items {
    background-color: #fff;
    padding: 10px 20px;

    .List {
      position: relative;

      .draggable {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);

        &:hover {
          cursor: pointer;
        }

        &.dragged {
          border: 1px solid #e2e2e2;
          padding: 0 10px;
        }
      }
    }

    .ant-list-item-meta {
      display: flex;
      align-items: center;
    }
  }
}
