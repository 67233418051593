@import "../../../../scss/index.scss";

.posts-list-web {
  margin: 50px 0;

  > h1 {
    text-align: center;
    color: $font-light;
    margin: 50px 0;
  }

  .post {
    color: $font-light;

    &__date {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $primary-color;
      margin-right: 20px;
      border-radius: 5px;
      text-transform: capitalize;
      padding: 5px 15px;
    }

    .ant-list-item-meta-title {
      color: $font-light;
      font-size: 16px;
      &:hover {
        color: $primary-color-dark;
      }
    }
  }
}
