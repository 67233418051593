@import "../../../../scss/index.scss";

.my-info {
  img {
    width: 200px;
    margin-bottom: 20px;
  }
  h4 {
    margin-bottom: 20px;
    color: $primary-color-dark;
  }

  .social-links {
    justify-content: flex-start;
  }
}
