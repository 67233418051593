$defaultFontSize: 16px;

$primary-color: #0098d3;
$primary-color-hover: #0280b3;
$primary-color-light: #6ec1e4;
$primary-color-dark: #8698a5;

$menu-color: #252527;

$font-light: #fff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #000;
$font-dark-light: #252527;
$font-dark: #000;

$border-light: #fff;
$border-grey-light: #e8e8e8;
$border-grey: #808080;
$border-grey-dark: #595959;
$border-dark-light: #252527;
$border-dark: #000;

$background-light: #fff;
$background-grey-light: #e8e8e8;
$background-grey: #808080;
$background-grey-dark: #595959;
$background-dark-light: #252527;
$background-dark: #000;
$background-success: #84b84c1f;
$background-error: #ff00001f;
$background-dark-web: #16202b;
$background-dark-light-web: #1a2734;

$success: #84b84c;
$error: #f00;

// Social Color
$youtube: #cd201f;
$twitter: #1da1f2;
$facebook: #3b5998;
$linkedin: #0077b5;

// Responsive down size
$media-breackpoint-down-xs: 413.98px;
$media-breackpoint-down-sm: 573.98px;
$media-breackpoint-down-md: 767.98px;
$media-breackpoint-down-lg: 991.98px;
$media-breackpoint-down-xl: 1199.98px;

// Responsive up size
$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 768px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1200px;
