.add-user-form {
  .form-add {
    text-align: center;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;

      i {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    .btn-submit {
      width: 100%;
    }
  }
}
