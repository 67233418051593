@import "./scss/index.scss";

.success {
  border: 1px solid $success;
  background-color: $background-success;
}

.error {
  border: 1px solid $error;
  background-color: $background-error;
}
